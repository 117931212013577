import ms from "ms";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useTranslations } from "@/i18n";
import type { Lang } from "@/types";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { useStore } from "@nanostores/react";
import { $globalOTP } from "./otp.store";
import { Button } from "@/components/ui/button";
import { useCountDown, useRequest } from "ahooks";
import { useState } from "react";
import { sendOTP, verifyOTP } from "@/services/otp";

export const GlobalOTPModal = ({ lang }: { lang: Lang }) => {
  const t = useTranslations(lang);
  const { modalOpen, method, target } = useStore($globalOTP);

  return (
    <AlertDialog
      open={modalOpen}
      onOpenChange={(v) => $globalOTP.setKey("modalOpen", v)}
    >
      <AlertDialogContent className="text-one">
        <AlertDialogHeader>
          <AlertDialogTitle>{t("auth.otp.title")}</AlertDialogTitle>
          <AlertDialogDescription>
            {t("auth.otp.enterCode")} <br />
            {t(`auth.otp.method.${method}`)}: {target}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <GlobalOTPModalContent lang={lang} />

        <AlertDialogFooter className="sm:justify-between">
          <AlertDialogCancel>{t("common.cancel")}</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const GlobalOTPModalContent = ({ lang }: { lang: Lang }) => {
  const duration = ms("3 minutes");
  const t = useTranslations(lang);

  const [targetDate, setTargetDate] = useState(Date.now() + duration);
  const [countdown, { minutes, seconds }] = useCountDown({ targetDate });
  const [code, setCode] = useState("");

  const codeFilled = code.length === 6;

  const { run: send, loading: sending } = useRequest(
    async () => {
      setCode("");
      setTargetDate(Date.now() + duration);
      const { method, target } = $globalOTP.get();
      await sendOTP(method, target);
    },
    {
      ready: countdown === 0,
      manual: true,
    },
  );

  const {
    data: verification,
    loading: verifying,
    mutate,
  } = useRequest(
    () => {
      const { method, target } = $globalOTP.get();
      return verifyOTP(method, target, code);
    },
    {
      ready: codeFilled,
      debounceWait: 500,
      onSuccess(result) {
        // result.isValid = true;
        if (result.isValid) {
          $globalOTP.get().onSuccess?.(result.token);
          $globalOTP.set({
            method: "email",
            modalOpen: false,
            target: "",
            valid: true,
          });
        }
      },
    },
  );

  return (
    <div className="grid gap-2">
      <div className="flex justify-center">
        <OTPInput
          code={code}
          setCode={(v) => {
            mutate(undefined);
            setCode(v);
          }}
        />
      </div>

      {verifying && (
        <div className="text-center text-sm">{t("common.verifying")}...</div>
      )}

      {codeFilled && !verifying && verification && !verification.isValid && (
        <div className="text-center text-sm text-destructive">
          {t("auth.otp.invalidCode")}
        </div>
      )}

      <div className="text-center text-sm">
        {t("auth.otp.timeLeft")}: {minutes}:{(seconds + "").padStart(2, "0")}
      </div>

      {(!verification || !verification.isValid) && countdown === 0 && (
        <div className="text-center">
          <Button variant="two" onClick={send} disabled={sending}>
            {t("common.resend")}
          </Button>
        </div>
      )}
    </div>
  );
};

const OTPInput = ({
  code,
  setCode,
  disabled,
}: {
  code: string;
  setCode: (v: string) => void;
  disabled?: boolean;
}) => {
  return (
    <InputOTP
      value={code}
      onChange={setCode}
      disabled={disabled}
      maxLength={6}
      autoFocus
    >
      <InputOTPGroup>
        <InputOTPSlot index={0} />
        <InputOTPSlot index={1} />
        <InputOTPSlot index={2} />
      </InputOTPGroup>
      <InputOTPSeparator />
      <InputOTPGroup>
        <InputOTPSlot index={3} />
        <InputOTPSlot index={4} />
        <InputOTPSlot index={5} />
      </InputOTPGroup>
    </InputOTP>
  );
};
